import React, { useState } from "react";
import {
  Paper,
  List,
  ListItem,
  ListItemText,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import "./cart.css"; // Make sure this CSS file includes your styles
import useAPIsendToSavvyPortal from "./useAPIsendToSavvyPortal"; // Import the hook

const Cart = ({
  items,
  onClose,
  show,
  clearCart,
  incrementQuantity,
  decrementQuantity,
  setCartItems,
  onSaveCartToOpportunity,
  token, // Accept the token prop
}) => {
  const [opportunityName, setOpportunityName] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [accountNumber, setAccountNumber] = useState("");
  const [isAccountNumberModalOpen, setIsAccountNumberModalOpen] =
    useState(false);

  const {
    createSavvyQuoteData,
    postData,
    createSavvyQuoteLoading,
    createSavvyQuoteError,
  } = useAPIsendToSavvyPortal();

  const handleQuantityChange = (e, itemId) => {
    const newQuantity = e.target.value;

    // Update the item's quantity in the cart
    setCartItems((currentItems) =>
      currentItems.map((item) =>
        item.id === itemId
          ? { ...item, quantity: Number(newQuantity) || 1 }
          : item
      )
    );
  };

  const copyToClipboard = () => {
    const itemListString = items
      .map((item) => {
        const price = Number(item.Price);
        const quantity = Number(item.quantity);
        const totalPrice = price * quantity;
        return `${item["ProductName"]} - Price: $${price.toFixed(
          2
        )}, Quantity: ${quantity}, Total Price: $${totalPrice.toFixed(
          2
        )}, SKU: ${item.SKU}`;
      })
      .join("\n");

    navigator.clipboard
      .writeText(itemListString)
      .then(() => {
        alert("Cart items copied to clipboard.");
      })
      .catch((err) => {
        console.error("Failed to copy cart items to clipboard:", err);
      });
  };

  const handleSaveCart = () => {
    setOpenDialog(true);
  };

  const handleSave = () => {
    onSaveCartToOpportunity(opportunityName);
    setOpportunityName(""); // Clear the input field
    setOpenDialog(false); // Close the dialog
  };

  const handleSendToSavvyPortal = () => {
    setIsAccountNumberModalOpen(true);
  };

  const handleAccountNumberSubmit = () => {
    const newCMsub = {
      accountNumber,
      cartItems: items,
    };

    postData(newCMsub, token); // Call the hook function
    setIsAccountNumberModalOpen(false); // Close the modal
  };

  return (
    <Paper elevation={3} className={`cart ${show ? "cart-open" : ""}`}>
      <Button onClick={onClose}>Close</Button>
      <List>
        {items.map((item, index) => (
          <ListItem key={index}>
            <ListItemText
              primary={item["ProductName"]}
              secondary={
                <>
                  {`Price: $${(
                    Number(item.Price) * Number(item.quantity)
                  ).toFixed(2)}`}
                  <br />
                  {`QTY: ${item.quantity}`}
                  <br />
                  {`SKU: ${item.SKU}`}
                </>
              }
            />
            <IconButton
              onClick={() => decrementQuantity(item.id)}
              className="no-select"
              size="small"
            >
              <RemoveIcon fontSize="small" />
            </IconButton>
            <input
              type="number"
              className="quantity-input no-select"
              value={item.quantity}
              onChange={(e) => handleQuantityChange(e, item.id)}
              min="1"
              style={{ width: "35px" }}
            />
            <IconButton
              onClick={() => incrementQuantity(item.id)}
              className="no-select"
              size="small"
            >
              <AddIcon fontSize="small" />
            </IconButton>
          </ListItem>
        ))}
      </List>
      <Button
        variant="contained"
        color="primary"
        style={{ marginTop: 20, marginRight: 10 }}
        onClick={copyToClipboard}
      >
        Copy to Clipboard
      </Button>
      <Button
        variant="contained"
        color="secondary"
        style={{ marginTop: 20, marginRight: 10 }}
        onClick={clearCart}
      >
        Clear Cart
      </Button>
      <Button
        variant="contained"
        color="primary"
        style={{ marginTop: 20 }}
        onClick={handleSaveCart}
      >
        Save
      </Button>
      <Button
        variant="contained"
        color="primary"
        style={{ marginTop: 20 }}
        onClick={handleSendToSavvyPortal}
      >
        Send to SavvyPortal
      </Button>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Save Cart</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="opportunityName"
            label="Opportunity Name"
            type="text"
            fullWidth
            value={opportunityName}
            onChange={(e) => setOpportunityName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={() => handleSave(opportunityName)}>Save</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isAccountNumberModalOpen}
        onClose={() => setIsAccountNumberModalOpen(false)}
      >
        <DialogTitle>Enter Account Number</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="accountNumber"
            label="Account Number"
            type="text"
            fullWidth
            value={accountNumber}
            onChange={(e) => setAccountNumber(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsAccountNumberModalOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleAccountNumberSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>
      {createSavvyQuoteLoading && <CircularProgress />}
      {createSavvyQuoteError && (
        <div style={{ color: "red" }}>Error: {createSavvyQuoteError}</div>
      )}
      {createSavvyQuoteData && (
        <div style={{ color: "green" }}>Success: {createSavvyQuoteData}</div>
      )}
    </Paper>
  );
};

export default Cart;

import React from "react";
import "./savedOpportunities.css"; // Import the CSS file for styling
import { List, ListItem, ListItemText, Paper } from "@mui/material";

const SavedOpportunities = ({ opportunities, onLoadOpportunity, show }) => {
  return (
    <Paper
      elevation={3}
      className={`saved-opportunities ${
        show ? "saved-opportunities-open" : ""
      }`}
    >
      <h2>Saved Carts</h2>
      <List>
        {opportunities && opportunities.length > 0 ? (
          opportunities.map((opportunity, index) => (
            <ListItem
              button
              key={index}
              onClick={() => onLoadOpportunity(opportunity.name)} // Load opportunity by name
            >
              <ListItemText primary={opportunity.name} />{" "}
              {/* Access name property */}
            </ListItem>
          ))
        ) : (
          <ListItem>
            <ListItemText primary="No saved opportunities" />
          </ListItem>
        )}
      </List>
    </Paper>
  );
};

export default SavedOpportunities;

function ItsLoadingSpinner() {
  // Inline styles for the spinner container and image
  const spinnerStyle = {
    width: "10px", // adjust width and height to fit your logo
    height: "10px", // adjust width and height to fit your logo
    position: "relative", // Required to position the child img absolutely
  };

  const imageStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%) scale(0.8)", // adjust scale for smaller size
    animation: "spin 1s linear infinite", // Referencing the keyframes defined in CSS
  };

  return (
    <div style={spinnerStyle}>
      <img
        style={imageStyle}
        src="https://i.ibb.co/TtRPRWs/ITsavvy-Logo-Bubble-1000px.png"
        alt="Loading"
      />
    </div>
  );
}

export default ItsLoadingSpinner;

import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Card,
  CardContent,
  CardActions,
  Typography,
} from "@mui/material";

const ProductDetailsModal = ({ product, open, onClose, onAddToCart }) => {
  const [promoDetails, setPromoDetails] = useState([]);
  const [showPromoCard, setShowPromoCard] = useState(false);
  const [upgradeDetails, setUpgradeDetails] = useState([]);
  const [showUpgradeCard, setShowUpgradeCard] = useState(false);

  const handleOpenProductPage = () => {
    const baseURL = "https://savvyportal.itsavvy.com/Product/Search/Keyword";
    const sku = product ? encodeURIComponent(product.SKU) : "";
    const url = `${baseURL}?keyword=${sku}&showPartsWithNoCost=True&isNewSearch=False&isLCM=False`;
    window.open(url, "productPageWindow", "noopener,noreferrer");
  };

  const handlePromotionClick = () => {
    let details = [];
    try {
      const promoObject =
        product && product.promo && product.promo.trim() !== "None"
          ? JSON.parse(product.promo)
          : {};
      Object.entries(promoObject).forEach(([key, value]) => {
        if (value) {
          details.push(`${key}: ${value}`);
        }
      });
      setPromoDetails(details);
      setShowPromoCard(true);
    } catch (error) {
      console.error("Failed to parse promo details:", error);
      setPromoDetails(["Error parsing promotion details."]);
      setShowPromoCard(true);
    }
  };
  console.log(product);
  const handleUpgradeClick = () => {
    let details = [];
    try {
      // Assuming `product.upgradeOptions` is a JSON string of the array.
      const upgradeArray =
        product.upgradeOptions &&
        product.upgradeOptions.trim() !== "" &&
        product.upgradeOptions.trim() !== "none"
          ? JSON.parse(product.upgradeOptions)
          : [];
      details = upgradeArray.map((option) => option); // Map directly since it's already an array.
      setUpgradeDetails(details);
      setShowUpgradeCard(true);
    } catch (error) {
      console.error("Failed to parse upgrade options:", error);
      setUpgradeDetails(["Error parsing upgrade options."]);
      setShowUpgradeCard(true);
    }
  };

  const handleClosePromoCard = () => {
    setShowPromoCard(false); // Only hide the promo card
  };
  const handleCloseUpgradeCard = () => {
    setShowUpgradeCard(false); // Only hide the promo card
  };

  const handleClose = () => {
    setPromoDetails([]); // Clear promo details
    setShowPromoCard(false); // Hide the promo card
    setUpgradeDetails([]); // Clear promo details
    setShowUpgradeCard(false); // Hide the promo card
    onClose(); // Call the original onClose prop
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{product ? product["ProductName"] : ""}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <br />
          <strong>Price: ${product ? product.Price : ""}</strong>
          <br />
          <strong>Description:</strong> {product ? product.Description : ""}
        </DialogContentText>
        {showPromoCard && (
          <Card>
            <CardContent>
              {promoDetails.map((detail, index) => (
                <Typography key={index}>{detail}</Typography>
              ))}
            </CardContent>
            <CardActions>
              <Button
                size="small"
                color="primary"
                onClick={handleClosePromoCard}
              >
                Close Promo Details
              </Button>
            </CardActions>
          </Card>
        )}
        {showUpgradeCard && (
          <Card>
            <CardContent>
              {upgradeDetails.map((detail, index) => (
                <Typography key={index}>{detail}</Typography>
              ))}
            </CardContent>
            <CardActions>
              <Button
                size="small"
                color="primary"
                onClick={handleCloseUpgradeCard}
              >
                Close Upgrade Details
              </Button>
            </CardActions>
          </Card>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            handleClose();
            onAddToCart(product);
          }}
        >
          Add to Cart
        </Button>
        <Button onClick={handleOpenProductPage}>Open Product Page</Button>
        {product && product.promo && product.promo.trim() !== "None" && (
          <Button onClick={handlePromotionClick}>View Promotion</Button>
        )}

        <Button onClick={handleUpgradeClick}>View Upgrades</Button>

        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProductDetailsModal;

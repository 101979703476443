import { Configuration } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId: "1433182e-75cb-4756-a8b4-8f75ea731314", // Application (client) ID
    authority:
      "https://login.microsoftonline.com/03179579-09bb-46ad-8562-3d9bf04c4d6c", // Directory (tenant) ID
    //redirectUri: 'https://lemon-flower-01cbc1c10.4.azurestaticapps.net/'
    // redirectUri: 'http://localhost:3000/'
    redirectUri: `${process.env.PUBLIC_URL}/`,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false, // Set to true for Internet Explorer 11
  },
};

// Add scopes
export const loginRequest = {
  scopes: ["User.Read", "api://1433182e-75cb-4756-a8b4-8f75ea731314/api-scope"],
};

import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { Typography, Box, Container, Paper } from "@mui/material";
import Button from "@mui/material/Button";

const SignInButton = () => {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance
      .loginPopup(loginRequest)
      .then((response) => {
        console.log("Login successful", response);
        // Optionally, force a component update if needed
      })
      .catch((e) => {
        console.error("Login failed", e);
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Paper
          elevation={3}
          sx={{
            p: 4,
            mt: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Button
            type="button"
            fullWidth
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
              backgroundColor: "#2F2F2F",
              ":hover": { backgroundColor: "#434343" },
            }}
            onClick={() => handleLogin()}
          >
            <img
              src={`${process.env.PUBLIC_URL}/microsoft-login.png`}
              alt="Microsoft Login"
              style={{ marginRight: 10, height: 24 }}
            />
            Login with Microsoft
          </Button>
        </Paper>
      </Box>
    </Container>
  );
};

export default SignInButton;

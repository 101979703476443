import { useState, useCallback } from "react";
import axios from "axios";

const useAPIsendToSavvyPortal = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const postData = useCallback(async (newCMsub, token) => {
    console.log("newCMsub", newCMsub);

    const jsonData = {
      Client: {
        spID: newCMsub.accountNumber,
      },
      skus: newCMsub.cartItems.map((item) => ({
        sku: item.SKU,
      })),
    };

    console.log(jsonData);

    if (!newCMsub || !token) {
      setData(null);
      setLoading(false);
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(
        "https://afa-cus-ms-automation.azurewebsites.net/api/createSavvyPortalQuote?code=CDHa3Wv6ixOa3lAKdr8ppKlYWEcdl8jqIsCrQEMwtnWiAzFuJ7MFoQ%3D%3D",
        jsonData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setData(response.data);
    } catch (err) {
      console.error("Error fetching data: ", err);
      setError(err.response ? err.response.data : "An unknown error occurred");
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    createSavvyQuoteData: data,
    postData,
    createSavvyQuoteLoading: loading,
    createSavvyQuoteError: error,
  };
};

export default useAPIsendToSavvyPortal;
